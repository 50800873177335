import React, { useState } from "react";
import "../../../../styles/global.css";
import moment from "moment";

const EmployeeLeaveSection = ({
  allocation,
  leaveRequests,
  getLeaveColor,
  employeeDetails,
}) => {
  // Mapping leave type abbreviations to their full forms
  const leaveTypeFullForm = {
    sl: "Sick Leave",
    el: "Earned Leave",
    wfh: "Work From Home",
    dl: "Duty Leave",
    cl: "Casual Leave",
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Number of items per page

  // Calculate the indices of the items to be displayed on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get the current items (leave requests) to display
  const currentLeaveRequests = leaveRequests?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getRandomColor = () => {
    const colors = [
      "bg-primary",
      "bg-success",
      "bg-info",
      "bg-warning",
      "bg-danger",
      "bg-secondary",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  // Helper function to get initials
  const getInitials = (leaveType) => {
    return leaveType
      ?.split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .join("");
  };

  if (leaveRequests) {
    return (
      <div>
        <h4>Leave Requests</h4>
        {leaveRequests.length > 0 ? (
          <>
            <table className="table table-hover mt-3">
              <thead>
                <tr>
                  <th></th>
                  <th>Leave Type</th>
                  <th>Applied On</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Requested Days</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {currentLeaveRequests.map((request) => {
                  const startDate = new Date(request.start_date);
                  const endDate = new Date(request.end_date);
                  const requestedDays =
                    (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;

                  return (
                    <tr key={request.id}>
                      <td>
                        <div
                          className={`avatar-circle ${getRandomColor()} d-flex justify-content-center align-items-center`}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        >
                          <span className="text-white font-weight-bold">
                            {getInitials(request?.leaves_master?.leave_type)}
                          </span>
                        </div>
                      </td>
                      <td>{request?.leaves_master?.leave_type || "Unknown"}</td>

                      <td>
                        {moment(request?.created_at).format("DD-MM-YYYY")}
                      </td>

                      <td>
                        {new Date(request.start_date).toLocaleDateString()}
                      </td>
                      <td>{new Date(request.end_date).toLocaleDateString()}</td>
                      <td>{requestedDays}</td>
                      <td>
                        {request.leave_status.charAt(0).toUpperCase() +
                          request.leave_status.slice(1)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="pagination">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              {[...Array(Math.ceil(leaveRequests.length / itemsPerPage))].map(
                (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => paginate(index + 1)}
                    className={currentPage === index + 1 ? "active" : ""}
                  >
                    {index + 1}
                  </button>
                )
              )}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={
                  currentPage === Math.ceil(leaveRequests.length / itemsPerPage)
                }
              >
                Next
              </button>
            </div>
          </>
        ) : (
          <p>No leave requests found for this employee.</p>
        )}
      </div>
    );
  }

  // Render leave allocations if allocation prop is passed
  return (
    <div
      key={allocation?.id}
      className="leave-allocation-block d-flex align-items-center mb-3"
    >
      <div
        className={`avatar-circle ${getRandomColor()} d-flex justify-content-center align-items-center`}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
        }}
      >
        <span className="text-white font-weight-bold">
          {getInitials(allocation?.leaves_master?.leave_type)}
        </span>
      </div>

      <div className="ml-3">
        <p className="font-weight-bold">
          {allocation?.leaves_master?.leave_type || "Unknown"}
        </p>

        <p>
          <strong>Available Leave Days:</strong> {allocation?.balance}
        </p>
        <p>
          <strong>Maximum Leave Days:</strong>{" "}
          {allocation?.leaves_master?.max_value}
        </p>
      </div>
    </div>
  );
};

export default EmployeeLeaveSection;
