import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import swal from "sweetalert2";
import { fetchAllLeaves, applyLeave } from "app/API/LeaveAPI"; // Ensure these are properly imported

const LeaveApplyModal = ({
  show,
  onClose,
  employeeId,
  token,
  employeeDetails,
  onLeaveApplied,
}) => {
  const [leaveDetails, setLeaveDetails] = useState({
    employee_id: employeeDetails?.id ? employeeDetails?.id : employeeId,
    leave_id: "",
    reason: "",
    start_date: "",
    end_date: "",
  });

  const [errors, setErrors] = useState({});
  const [leaves, setLeaves] = useState([]);
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    if (show) {
      const fetchLeaves = async () => {
        try {
          const leaveData = await fetchAllLeaves(token);
          setLeaves(leaveData.leaves);
        } catch (error) {
          console.error("Error fetching leave data:", error);
        }
      };

      fetchLeaves();
    }
  }, [show, token]);

  useEffect(() => {
    if (leaveDetails.leave_id) {
      const selectedLeave = employeeDetails?.leave_employee_allocations?.find(
        (allocation) => allocation.leave_id === Number(leaveDetails.leave_id)
      );
      setBalance(selectedLeave ? selectedLeave?.balance : null);
    }
  }, [leaveDetails.leave_id, employeeDetails]);

  console.log("show employee id:", employeeId);

  console.log("show employee details:", employeeDetails);

  const validateForm = () => {
    const newErrors = {};
    console.log("inside validation");
    // Validation for checking if no leave allocations are available
    if (employeeDetails?.leave_employee_allocations?.length === 0) {
      newErrors.no_leave_allocations = "No leaves assigned to you.";
    }

    // General validation for all fields except reason
    Object.keys(leaveDetails).forEach((key) => {
      if (!leaveDetails[key] && key !== "reason") {
        newErrors[key] = `${key.replace("_", " ")} is required.`;
      }
    });

    // Start date and end date validation
    if (new Date(leaveDetails.start_date) > new Date(leaveDetails.end_date)) {
      newErrors.end_date = "End date cannot be before the start date.";
    }

    // Insufficient balance validation
    if (balance !== null) {
      const leaveDuration =
        (new Date(leaveDetails.end_date) - new Date(leaveDetails.start_date)) /
          (1000 * 3600 * 24) +
        1; // calculate days
      if (leaveDuration > balance) {
        newErrors.leave_balance = "Insufficient leave balance.";
      }
    }

    // CL leave can only be one day
    if (
      leaveDetails.leave_id === "5" &&
      (new Date(leaveDetails.end_date) - new Date(leaveDetails.start_date)) /
        (1000 * 3600 * 24) !==
        0
    ) {
      newErrors.leave_type =
        "Casual Leave (CL) can only be applied for one day at a time.";
    }
    if (leaveDetails.reason == "") {
      newErrors.reason = "provide reason for leave";
    }

    setErrors(newErrors);
    // Return false if there are any validation errors to block form submission
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    console.log("show leave details:", leaveDetails);

    setLeaveDetails((prevState) => ({
      ...prevState,
    }));

    if (!validateForm()) {
      return; // Prevent form submission if validation fails
    }

    try {
      const response = await applyLeave(leaveDetails, token);
      swal.fire({
        title: "Success!",
        text: response.msg || "Leave applied successfully.",
        icon: "success",
        timer: 1500,
      });
      onClose();
      setLeaveDetails({
        employee_id: employeeId,
        leave_id: "",
        reason: "",
        start_date: "",
        end_date: "",
      });
      onLeaveApplied();
    } catch (error) {
      console.error("Error applying leave:", error);
      let errorMessage = "Failed to apply leave.";

      if (error.response && error.response.status === 500) {
        const responseData = error.response.data;
        if (responseData.message && responseData.message.message) {
          errorMessage = responseData.message.message;
        }
      }

      swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        timer: 1500,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update leaveDetails state
    setLeaveDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    // Dynamically clear errors for the field being updated
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name]; // Remove error for the current field
      return newErrors;
    });

    // Additional dynamic validations (e.g., end date logic)
    if (name === "start_date" || name === "end_date") {
      const startDate = name === "start_date" ? value : leaveDetails.start_date;
      const endDate = name === "end_date" ? value : leaveDetails.end_date;

      if (new Date(startDate) > new Date(endDate)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          end_date: "End date cannot be before the start date.",
        }));
      } else {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors.end_date;
          return newErrors;
        });
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setLeaveDetails({
          employee_id: employeeDetails?.id ? employeeDetails?.id : employeeId,
          leave_id: "",
          reason: "",
          start_date: "",
          end_date: "",
        });
        setErrors({});
        setBalance(null);
        onClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Apply for Leave</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Leave Type Dropdown */}
          <Form.Group controlId="leave_id">
            <Form.Label>Leave Type:</Form.Label>
            <Form.Select
              as="select"
              name="leave_id"
              value={leaveDetails.leave_id}
              onChange={handleInputChange}
              isInvalid={!!errors.leave_id}
            >
              <option value="">Select Leave Type</option>
              {employeeDetails?.leave_employee_allocations?.map(
                (allocation) => {
                  const leaveMaster = leaves.find(
                    (leave) => leave.id === allocation.leave_id
                  );
                  return leaveMaster ? (
                    <option key={leaveMaster.id} value={leaveMaster.id}>
                      {leaveMaster.leave_type} (Balance: {allocation.balance})
                    </option>
                  ) : null;
                }
              )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.leave_id}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Balance Display */}
          {leaveDetails.leave_id && balance !== null && (
            <div className="mb-3">
              <strong>Balance:</strong> {balance} day{balance > 1 ? "s" : ""}
            </div>
          )}

          {/* Reason */}
          <Form.Group controlId="reason">
            <Form.Label>Reason:</Form.Label>
            <Form.Control
              type="text"
              name="reason"
              value={leaveDetails.reason}
              onChange={handleInputChange}
              isInvalid={!!errors.reason}
            />
            <Form.Control.Feedback type="invalid">
              {errors.reason}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Start Date */}
          <Form.Group controlId="start_date">
            <Form.Label>Start Date:</Form.Label>
            <Form.Control
              type="date"
              name="start_date"
              value={leaveDetails.start_date}
              onChange={handleInputChange}
              isInvalid={!!errors.start_date}
              min={new Date().toISOString().split("T")[0]} // Disable past dates
            />
            <Form.Control.Feedback type="invalid">
              {errors.start_date}
            </Form.Control.Feedback>
          </Form.Group>

          {/* End Date */}
          <Form.Group controlId="end_date">
            <Form.Label>End Date:</Form.Label>
            <Form.Control
              type="date"
              name="end_date"
              value={leaveDetails.end_date}
              onChange={handleInputChange}
              isInvalid={!!errors.end_date}
              min={
                leaveDetails.start_date ||
                new Date().toISOString().split("T")[0]
              } // Disable past dates and ensure it’s not before start_date
            />
            <Form.Control.Feedback type="invalid">
              {errors.end_date}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Error for insufficient balance */}
          {errors.leave_balance && (
            <div className="text-danger">{errors.leave_balance}</div>
          )}

          {/* Error for CL leave restriction */}
          {errors.leave_type && (
            <div className="text-danger">{errors.leave_type}</div>
          )}

          {/* Error for no leave allocations */}
          {errors.no_leave_allocations && (
            <div className="text-danger">{errors.no_leave_allocations}</div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setLeaveDetails({
              employee_id: employeeDetails?.id
                ? employeeDetails?.id
                : employeeId,
              leave_id: "",
              reason: "",
              start_date: "",
              end_date: "",
            });
            setErrors({});
            setBalance(null);
            onClose();
          }}
        >
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LeaveApplyModal;
