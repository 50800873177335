import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const LoanList = lazy(() => import("./LoanList"));


const loanroutes = [
  {
    path: "/loan/list",
    element: <LoanList />,
    auth: [...authRoles.admin],
  }
];

export default loanroutes;
