import moment from "moment";
import { useState, useEffect } from "react";
import { fetchSalaries } from "app/API/EmployeeAPI";

const MySalary = ({ employeeDetails,triggerSalaryRefetch }) => {
  const [salaryList, setSalaryList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [month, setMonth] = useState(moment().month() + 1);
  const [year, setYear] = useState(moment().year());
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const StoredToken = localStorage.getItem("token");

  const handleMonthChange = (selectedMonth) => {
    setMonth(selectedMonth);
    fetchData(currentPage, itemsPerPage, selectedMonth, year);
  };

  const handleYearChange = (selectedYear) => {
    setYear(selectedYear);
    fetchData(currentPage, itemsPerPage, month, selectedYear);
  };

  const handleResetFilter = () => {
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();
    setMonth(currentMonth);
    setYear(currentYear);
    fetchData(currentPage, itemsPerPage, currentMonth, currentYear);
  };

  const fetchData = async (page, limit, month, year) => {
    try {
      const response = await fetchSalaries(
        employeeDetails?.id,
        month,
        year,
        page,
        limit,
        StoredToken
      );
      const salaryData = response.salary_calculations.map((record, index) => ({
        ...record,
        index: (page - 1) * limit + index + 1,
      }));
      setSalaryList(salaryData);
      setTotalItems(response.total_count);
    } catch (error) {
      console.error("Error fetching salary data:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage, itemsPerPage, month, year);
  }, [currentPage, itemsPerPage, month, year,triggerSalaryRefetch]);

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!filterDropdownOpen);
  };

  const handlePageChange = (page) => setCurrentPage(page);

  

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getVisiblePages = () => {
    const pageRange = 5;
    const start = Math.max(1, currentPage - Math.floor(pageRange / 2));
    const end = Math.min(totalPages, start + pageRange - 1);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };
  return (
    <div>
      <div className="d-flex justify-content-end align-items-center mb-3 gap-3">
        <div className="dropdown">
          <button
            type="button"
            className="btn btn-primary dropdown-toggle"
            onClick={toggleFilterDropdown}
            style={{
              backgroundColor: "#BBC5EA",
              borderColor: "#BBC5EA",
              color: "#000000",
              height: "39px",
              width: "82px",
            }}
          >
            Filter&nbsp;
            <i className="fa fa-filter" aria-hidden="true"></i>
          </button>

          {filterDropdownOpen && (
            <div
              className="dropdown-menu"
              style={{
                display: "block",
                position: "absolute",
                right: 0,
                left: "auto",
                width: "320px",
                padding: "20px",
                borderRadius: "10px",
                border: "2px solid #DEE2E6",
              }}
            >
              <div style={{ marginBottom: "15px" }}>
                <label>Month:</label>
                <select
                  className="form-select"
                  onChange={(e) =>
                    handleMonthChange(parseInt(e.target.value, 10))
                  }
                  value={month}
                >
                  {moment.months().map((monthName, index) => (
                    <option key={index} value={index + 1}>
                      {monthName}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ marginBottom: "15px" }}>
                <label>Year:</label>
                <select
                  className="form-select"
                  onChange={(e) =>
                    handleYearChange(parseInt(e.target.value, 10))
                  }
                  value={year}
                >
                  {Array.from(
                    { length: 10 },
                    (_, i) => moment().year() - i
                  ).map((yearOption) => (
                    <option key={yearOption} value={yearOption}>
                      {yearOption}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ marginTop: "15px" }}>
                <button
                  type="button"
                  className="btn"
                  style={{
                    color: "#0075FF",
                    borderColor: "#0075FF",
                    fontSize: "15px",
                  }}
                  onClick={handleResetFilter}
                >
                  Reset Filters
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <table className="table common-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Month</th>
            <th>Year</th>
            <th>Att. Days</th>
            <th>Approved Leaves</th>
            <th>Working Days</th>
            <th>Holiday's</th>

            <th>Calc. Salary</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {salaryList.length > 0 ? (
            salaryList.map((item) => (
              <tr key={item.id}>
                <td>{item.index}</td>
                <td>
                  {moment()
                    .month(item.month - 1)
                    .format("MMMM")}
                </td>
                <td>{item.year}</td>
                <td>{item.attendance_days}</td>
                <td>{item.approved_leaves}</td>
                <td>{item.working_days}</td>
                <td>{item.monthly_holidays}</td>
                <td>{item.salary_processed.toFixed(2)}</td>
                <td>{item.status}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">No salary records available</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="pagination-container">
        <div className="items-per-page"></div>

        <div className="pagination-controls">
          <button
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          >
            First
          </button>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>

          {getVisiblePages().map((page) => (
            <button
              key={page}
              className={currentPage === page ? "active" : ""}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
          <button
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            Last
          </button>
        </div>
      </div>
    </div>
  );
};

export default MySalary;
