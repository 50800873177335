import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  ListGroup,
  DropdownButton,
  Accordion,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { MdDelete } from "react-icons/md";
import { Modal, Form } from "react-bootstrap";
import { DownOutlined } from "@ant-design/icons";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import { BsPlusCircle } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { Checkbox } from "antd";
import UpdateRemarksModal from "../Common/UpdateRemarksModal";
import getQuotationsByLeadId from "app/API/QuotationAPI";
import {
  getSingleClientInformation,
  updateClientInformation,
  updateClientContact,
  addClientContact,
  deleteClientContact,
  getClientList,
} from "app/API/ClientAPI";
import { fetchLeadListApi } from "app/API/LeadAPI";
import { serverAddress } from "constants";
import { Breadcrumb } from "@gull";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import UpdateClientModal from "./UpdateClientModal";
import UpdateContactModal from "./UpdateContactModal";
import swal from "sweetalert2";
import { Tabs, Tab } from "react-bootstrap";
import { Dropdown, Menu, Space } from "antd";
import { DeleteContactModal } from "./DeleteContactModal";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import LeadList from "../Leads/LeadList";
import { fetchLeadDetails } from "app/API/LeadAPI";
import BootstrapTable from "react-bootstrap-table-next";
const ClientInformation = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [clientDetails, setClientDetails] = useState([]);
  const [showClientModal, setShowClientModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLeadDeleteModal, setShowLeadDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState("overview");
  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [updateRemarksLeadId, setUpdateRemarksLeadId] = useState(null);
  const [updatedRemarks, setUpdatedRemarks] = useState("");
  const [showUpdateRemarksModal, setShowUpdateRemarksModal] = useState(false);
  const [selectedHandledBy, setSelectedHandledBy] = useState("");
  const [leadQuotation, setLeadQuotation] = useState(null);
  const [clientData, setClientData] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [deleteLeadId, setDeleteLeadId] = useState(null);
  const [totalLeads, setTotalLeads] = useState(0);
  const storedToken = localStorage.getItem("token");

  const [leadDataChanged, setLeadDataChanged] = useState(false);
  const [deleteInput, setDeleteInput] = useState("");

  // const location = useLocation();
  // const { lead } = location.state || {};
  const token = localStorage.getItem("token");

  const [filterOptions, setFilterOptions] = useState({
    "Quotation Pending": false,
    "Quotation Sent": false,
    "Waiting for Customer Response": false,
    "Revised Quotation to be Sent": false,
    "Order Closed with Logway": false,
    "Order Closed with Other Supplier": false,
    "Project Delayed": false,
  });

  // Function to handle row click
  const handleRowClick = (row) => {
    setSelectedLeadId(row.id);
    console.log("Selected Lead ID:", row.id);
    // Call any function you want with the selected lead ID
  };

  const [formData, setFormData] = useState({
    uuid: uuid,
    company_name: "",
    billing_address: "",
    shipping_address: "",
    type: "",
    category: "",
    gstin_number: "",
    client_code: "",
    token: token ? token : "",
  });
  const { SearchBar } = Search;
  const [contactFormData, setContactFormData] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    alternate_phone_number: "",
    uuid: uuid,
  });
  const headerStyle = {
    // Adjust padding as needed
    fontSize: "12px ", // Adjust font size as needed
  };
  const headerTextStyle = {
    margin: 0, // Reset margin
    fontSize: "inherit", // Inherit font size from headerStyle
  };

  const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "Next page",
    prePageTitle: "Pre page",
    firstPageTitle: "First page",
    lastPageTitle: "Last page",
    showTotal: true,
    totalSize: totalLeads,
  };

  const fetchLeadListByClient = async (options, uuid = uuid, employee_id) => {
    try {
      const storedToken = localStorage.getItem("token");

      // Pass the employee_id as handledBy to the API
      const data = await fetchLeadListApi(
        options,
        uuid,
        storedToken,
        employee_id
      );

      setLeadData(data?.leads);
      setTotalLeads(data?.count);
    } catch (error) {
      throw new Error(error.message || "Error fetching lead list:");
    }
  };
  const fetchEmployeeList = async () => {
    try {
      const storedToken = localStorage.getItem("token");
      const response = await axios.get(
        `${serverAddress}/employee/getEmployees`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );

      const employees = response.data.employees || [];
      setEmployeeList(employees);
      console.log(employees);
    } catch (error) {
      throw new Error(
        error.response.data.message || "Error fetching employee list:"
      );
    }
  };

  useEffect(() => {
    if (activeTab === "leads" || activeTab === "leadQuotation") {
      fetchLeadListByClient(null, uuid);
    }
    fetchEmployeeList();
  }, [activeTab, uuid]);

  const sortableColumn = [
    {
      dataField: "index",
      text: "No",
      sort: true,
      headerStyle: { width: "5%" },
      style: { width: "5%" },
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "lead_code",
      text: "Lead Code",
      sort: true,
      headerStyle: { width: "10%" },
      style: { width: "10%" },
      formatter: (cell, row) => (
        <div
          style={{ cursor: "pointer" }} // Show pointer cursor to indicate clickability
          onClick={(e) => redirectToQuotation(e, row.id)}
        >
          <span>{cell}</span>
        </div>
      ),
    },
    {
      dataField: "item_subject",
      text: "Subject",
      sort: true,
      headerStyle: { width: "15%" },
      style: { width: "15%" },
      formatter: (cell, row) => (
        <div
          style={{ cursor: "pointer" }} // Show pointer cursor to indicate clickability
          onClick={(e) => redirectToQuotation(e, row.id)}
        >
          <span>{cell}</span>
        </div>
      ),
    },
    {
      dataField: "project_type",
      text: "Type",
      sort: true,
      headerStyle: { width: "20%" },
      style: { width: "20%" },
      formatter: (cell, row) => (
        <div
          style={{ cursor: "pointer" }} // Show pointer cursor to indicate clickability
          onClick={(e) => redirectToQuotation(e, row.id)}
        >
          <span>{cell}</span>
        </div>
      ),
    },
    {
      dataField: "employee.id",
      text: "Handled By",
      sort: true,
      formatter: (cell, record) => {
        const { employee } = record;
        return `${employee.first_name} ${employee.last_name}`;
      },
      headerStyle: { width: "10%" },
      style: { width: "10%" },
    },
    {
      dataField: "lead_statuses[0].remarks",
      text: "Remarks",
      sort: true,
      headerStyle: { width: "10%" },
      style: { width: "10%" },
    },
    {
      dataField: "lead_statuses[0].status",
      text: "Status",
      sort: true,
      headerStyle: { width: "15%" },
      style: { width: "15%" },
    },

    {
      dataField: "Action",
      text: "Action",
      sort: false,
      key: "action",
      fixed: "right",
      headerStyle: { width: "9%" },
      style: { width: "9%" },
      formatter: (cell, record) => (
        <Space size="middle">
          <Dropdown overlay={menu(record)}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              Options <DownOutlined />
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];
  const employeeOptions =
    employeeList?.map((employee) => ({
      label: `${employee.first_name} ${employee.last_name}`,
      value: `${employee.first_name} ${employee.last_name}`,
      id: `${employee.id}`,
    })) || [];
  const menu = (record) => (
    <Menu>
      <Menu.Item key="4" onClick={() => showDeleteConfirmation(record.id)}>
        Delete Lead
      </Menu.Item>

      <Menu.Item key="5" onClick={() => handleUpdateModal(record)}>
        Lead handled by
      </Menu.Item>

      <Menu.Item key="6" onClick={() => handleUpdateStatusModal(record)}>
        Update Lead Status
      </Menu.Item>

      <Menu.Item key="7" onClick={() => handleUpdateRemarksModal(record)}>
        Update Remarks
      </Menu.Item>
    </Menu>
  );

  const handleUpdateRemarksModal = (lead) => {
    setUpdateRemarksLeadId(lead.id);
    setUpdatedRemarks(lead.lead_statuses[0].remarks || ""); // Set default value
    setShowUpdateRemarksModal(true);
  };

  const handleUpdateRemarks = async () => {
    try {
      // Validate remarks
      if (!updatedRemarks.trim()) {
        toast.error("Remarks cannot be empty");
        return;
      }
      const response = await axios.post(
        `${serverAddress}/lead/add/remark`,
        {
          id: updateRemarksLeadId,
          remarks: updatedRemarks,
          token: localStorage.getItem("token"),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (response.data && response.data.updated) {
        toast.success("Remarks updated successfully");
        fetchLeadListByClient(null, uuid); // Fetch the updated lead list
      } else {
        toast.error("Failed to update remarks");
      }
    } catch (error) {
      toast.error("An error occurred while updating remarks");
    } finally {
      setShowUpdateRemarksModal(false);
      setUpdateRemarksLeadId(null);
      setUpdatedRemarks("");
    }
  };

  const handleUpdateStatusModal = (lead) => {
    setSelectedLead(lead);
    setShowUpdateStatusModal(true);
  };

  const handleUpdateStatus = async () => {
    try {
      if (!selectedStatus) {
        toast.error("Please select a status before updating");
        return;
      }

      const { id } = selectedLead;
      const response = await axios.put(
        `${serverAddress}/lead/update/lead-status`,
        {
          status: selectedStatus,
          id,
          token: localStorage.getItem("token"),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (response.status === 200) {
        toast.success("Lead status updated successfully");
        fetchLeadListByClient(null, uuid); // Call the function to fetch the updated lead list
      } else {
        toast.error("Failed to update lead status");
      }
    } catch (error) {
      toast.error("An error occurred while updating the lead status");
    } finally {
      setShowUpdateStatusModal(false);
      setSelectedLead(null);
      setSelectedStatus("");
    }
  };

  const handleUpdateModal = (lead) => {
    setSelectedLead(lead);
    setSelectedEmployeeId(lead.employee.id);
    setShowUpdateModal(true);
  };
  const handleUpdateLead = async () => {
    try {
      const { uuid } = selectedLead;
      const response = await axios.put(
        `${serverAddress}/lead/update/handled_by`,
        {
          employee_id: selectedEmployeeId,
          uuid,
          token: localStorage.getItem("token"),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (response.status === 200) {
        toast.success("Lead updated successfully");
        fetchLeadListByClient(null, uuid); // Call the function to fetch the updated lead list
      } else {
        toast.error("Failed to update lead");
      }
    } catch (error) {
      toast.error("An error occurred while updating the lead");
    } finally {
      setShowUpdateModal(false);
      setSelectedLead(null);
      setSelectedEmployeeId(null);
    }
  };
  const showDeleteConfirmation = (leadId) => {
    setDeleteLeadId(leadId);
    setShowLeadDeleteModal(true);
  };
  const handleDeleteLead = async () => {
    try {
      const response = await axios.delete(
        `${serverAddress}/lead/${deleteLeadId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (response.status === 200) {
        toast.success("Lead deleted successfully");
        fetchLeadListByClient(null, uuid); // Call the function to fetch the updated lead list
      } else {
        toast.error("Failed to delete lead");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the lead");
    } finally {
      setDeleteLeadId(null);
      setDeleteInput("");
      setShowLeadDeleteModal(false);
    }
  };

  const handleAddLead = () => {
    // Replace "/add-lead" with the actual path to your add lead component
    navigate("/lead/create");
  };
  const handleFilterChange = (option) => {
    setFilterOptions((prevOptions) => {
      const newOptions = { ...prevOptions, [option]: !prevOptions[option] };
      // Call fetchLeadList after updating the filter options
      fetchLeadListByClient(newOptions, uuid);
      return newOptions;
    });
  };

  const handleHandledByChange = (e) => {
    const selectedHandledBy = e.target.value;
    setSelectedHandledBy(selectedHandledBy);

    // Call fetchLeadList with updated employee_id (handledBy)
    fetchLeadListByClient(filterOptions, uuid, selectedHandledBy);
  };
  // Render the dropdown with checkboxes
  const renderFilterDropdown = () => (
    <Dropdown overlay={filterMenu} trigger={["click"]}>
      <Button
        className="py-2 mx-1"
        onClick={(e) => {
          e.stopPropagation(); // Prevents the event from bubbling up
        }}
        style={{
          backgroundColor: "#BBC5EA",
          borderColor: "#BBC5EA",
          color: "#000000",
          height: "39px",
          width: "82px",
          marginLeft: "-24px",
          // marginRight: "4px",
        }}
      >
        Filter&nbsp;
        {/* <DownOutlined /> */}
        <i className="fa fa-filter" aria-hidden="true"></i>
      </Button>
    </Dropdown>
  );

  // Menu for filter dropdown
  const filterMenu = (
    <Menu>
      {Object.keys(filterOptions).map((option) => (
        <Menu.Item key={option}>
          <Checkbox
            checked={filterOptions[option]}
            onChange={() => handleFilterChange(option)}
          >
            {option}
          </Checkbox>
        </Menu.Item>
      ))}
      <Menu.Item key="handled by" onClick={(e) => e.stopPropagation()}>
        <>
          <div className="dropdown-divider" style={{ margin: "10px 0" }}></div>

          <div className="row align-items-center" style={{ padding: "10px 0" }}>
            <div
              className="col-md-4"
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "15px",
                fontWeight: "500",
                whiteSpace: "nowrap", // Make the label bold if necessary
              }}
            >
              Handled By
            </div>

            <div className="col-md-8">
              <select
                className="form-select"
                value={selectedHandledBy}
                onChange={handleHandledByChange}
              >
                <option value="">Select Handled By</option>
                {employeeOptions.map((employee) => (
                  <option key={employee.value} value={employee.id}>
                    {employee.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      </Menu.Item>
    </Menu>
  );

  const handleExportDoc = (e) => {
    // console.log("line 567:", storedToken);
    const exportUrl = `${serverAddress}/lead/export/doc?folderName=lead`;

    axios({
      method: "post",
      url: exportUrl,
      responseType: "json",
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((response) => {
        const downloadUrl = response.data.url;

        // Trigger download using the provided URL
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "client-data.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: "Failed to export document",
          type: "error",
          icon: "error",
          timer: 1500,
        });
      })
      .finally(() => {});
  };

  const handleClick = async (selectedUuid) => {
    try {
      await navigate(`/client/information/${selectedUuid}`);

      const clientResponse = await getSingleClientInformation(uuid, token);
      setClientDetails(clientResponse);

      // Clear existing quotation data and fetch new data for the selected UUID
      // setQuotationData([]);
      // setPage(1); // Reset page to 1 when fetching new data
      // setHasMore(true); // Reset hasMore to true

      // const quotationResponse = await fetchAllQuotation(
      //   storedToken,
      //   1,
      //   limit,
      //   search
      // );

      // if (
      //   quotationResponse &&
      //   quotationResponse.saleOffer &&
      //   quotationResponse.saleOffer.length > 0
      // ) {
      //   setQuotationData(quotationResponse.saleOffer);

      //   setHasMore(
      //     quotationResponse.totalSaleOffer > quotationResponse.saleOffer.length
      //   );
      // } else {
      //   setHasMore(false);
      // }
    } catch (error) {
      swal.fire({
        title: "Error!",
        text: "Error fetching client by UUID",
        type: "error",
        icon: "error",
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    fetchClientList();
    handleClick(uuid);
  }, []);

  const fetchClientList = async () => {
    try {
      const data = await getClientList(storedToken);

      // let updatedClientList = data.clients.map(
      //   (
      //     {
      //       id,
      //       uuid,
      //       company_name,
      //       billing_address,
      //       shipping_address,
      //       type,
      //       category,
      //       created_at,
      //       client_code,
      //     },
      //     ind
      //   ) => ({
      //     id: id, // Use 'id' as the identifier for each client
      //     uuid: uuid,
      //     company_name,
      //     billing_address,
      //     shipping_address,
      //     type,
      //     category,
      //     created_at,
      //     client_code,
      //     index: ind + 1,
      //   })
      // );

      setClientData(data.clients);
    } catch (error) {
      swal.fire({
        title: "Error!",
        text: "Failed to fetch client list.",
        type: "error",
        icon: "error",
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientResponse = await getSingleClientInformation(uuid, token);
        setClientDetails(clientResponse);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [uuid]);

  // useEffect(() => {
  //   if (leadDataChanged) {
  //     setActiveTab("leadQuotation");
  //     setLeadDataChanged(false);
  //   }
  // }, [leadDataChanged]);

  // console.log("show lead", leadData);

  useEffect(() => {
    const fetchLeadQuotation = async () => {
      try {
        if (leadData) {
          const response = await fetchLeadDetails(selectedLeadId, token);

          setLeadQuotation(response);
          setLeadDataChanged(true); // Set leadDataChanged to true when lead data changes
          // console.log("Lead Quotation Data:", response);
        }
      } catch (error) {
        // console.error("Error fetching lead details:", error);
        swal.fire({
          title: "Error!",
          text: "Failed to fetch lead details.",
          type: "error",
          icon: "error",
          timer: 1500,
        });
      }
    };
    fetchLeadQuotation();
  }, [leadData, token]);

  function redirectToQuotation(e, id) {
    e.preventDefault();
    setActiveTab("leadQuotation");
    // getQuotations(id);
  }

  // const getQuotations = () => {
  //   const params = {
  //     token,
  //     id: uuid,
  //   };

  //   // Call the API to get quotations by lead ID
  //   getQuotationsByLeadId(params);
  // };

  const leadColumn = [
    {
      dataField: "index",
      text: "No",
      sort: true,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "lead_id",
      text: "Lead Id",
      sort: true,
    },
    {
      dataField: "id",
      text: "Quotation ID",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Quotation Date",
      sort: true,
      formatter: (cell, row) => {
        const date = new Date(cell);
        return date.toLocaleDateString();
      },
    },
  ];

  const handleOpenClientModal = () => {
    setShowClientModal(true);
    // Pre-fill form data with existing client details
    if (clientDetails.length > 0) {
      const client = clientDetails[0];
      // console.log("show client", client);
      setFormData({
        ...formData,
        client_code: client?.client_code,
        company_name: client?.company_name,
        billing_address: client?.billing_address,
        shipping_address: client?.shipping_address,
        type: client?.type,
        category: client?.category,
        country: client?.client_country,
        gstin_number: client?.gstin_number,
      });
    }
  };

  const handleOpenContactModal = (contact) => {
    setShowContactModal(true);
    // Pre-fill form data with existing contact details
    if (contact) {
      setContactFormData({
        id: contact.id,
        first_name: contact.first_name,
        last_name: contact.last_name,
        email_address: contact.email_address,
        phone_number: contact.phone_number,
        alternate_phone_number: contact.alternate_phone_number,
      });
    }
  };

  const handleAddContactModal = () => {
    setShowContactModal(true);
    // Clear form data when adding a new contact
    setContactFormData({
      ...contactFormData,
      id: "",
      first_name: "",
      last_name: "",
      email_address: "",
      phone_number: "",
      alternate_phone_number: "",
    });
  };

  const handleCloseModal = () => {
    setShowClientModal(false);
    setShowContactModal(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormChangeForContact = (e) => {
    const { name, value } = e.target;
    setContactFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateFormData = (formData) => {
    if (!formData.country) {
      swal.fire({
        title: "Validation Error",
        text: "Country cannot be empty.",
        type: "error",
        icon: "error",
        timer: 2000,
      });
      return false;
    }

    if (!formData.category) {
      swal.fire({
        title: "Validation Error",
        text: "Category cannot be empty.",
        type: "error",
        icon: "error",
        timer: 2000,
      });
      return false;
    }

    if (!formData.company_name) {
      swal.fire({
        title: "Validation Error",
        text: "Company Name cannot be empty.",
        type: "error",
        icon: "error",
        timer: 2000,
      });
      return false;
    }

    return true;
  };

  const handleClientSubmit = async () => {
    if (!validateFormData(formData)) {
      return; // Stop further execution if validation fails
    }

    try {
      // await updateClientInformation(formData, token);
      // swal.fire({
      //   title: "Updated!",
      //   text: "Client information has been updated.",
      //   type: "success",
      //   icon: "success",
      //   timer: 1500,
      // });
      // const clientResponse = await getSingleClientInformation(uuid, token);
      // setClientDetails(clientResponse);
      // handleCloseModal();
    } catch (error) {
      swal.fire({
        title: "Error!",
        text: "Failed to update client information.",
        type: "error",
        icon: "error",
        timer: 1500,
      });
    }
  };

  const handleContactSubmit = async () => {
    try {
      if (contactFormData.id) {
        // If id is present, it means we're updating an existing contact
        await updateClientContact(contactFormData, token);

        swal.fire({
          title: "Updated!",
          text: "Contact information has been updated.",
          type: "success",
          icon: "success",
          timer: 1500,
        });
      } else {
        // If id is not present, it means we're adding a new contact
        await addClientContact(contactFormData, token);

        swal.fire({
          title: "Added!",
          text: "Contact information has been added.",
          type: "success",
          icon: "success",
          timer: 1500,
        });
      }

      const clientResponse = await getSingleClientInformation(uuid, token);
      setClientDetails(clientResponse);
      handleCloseModal();
    } catch (error) {
      // console.error("Error updating contact information:", error);
      swal.fire({
        title: "Error!",
        text: "Failed to update contact information.",
        type: "error",
        icon: "error",
        timer: 1500,
      });
    }
  };

  const handleDeleteContactModal = (contact) => {
    // console.log("line 90:",contact);
    setShowDeleteModal(true);
    setContactFormData({
      ...contactFormData,
      id: contact.id,
    });
  };

  const handleDeleteConfirmationChange = (e) => {
    setDeleteConfirmation(e.target.value);
  };

  const handleContactDelete = async () => {
    if (deleteConfirmation.toLowerCase() === "delete") {
      try {
        // Make the API call to delete the contact
        await deleteClientContact(contactFormData, token);

        swal.fire({
          title: "Deleted!",
          text: "Contact has been deleted.",
          type: "success",
          icon: "success",
          timer: 1500,
        });

        const clientResponse = await getSingleClientInformation(uuid, token);
        setClientDetails(clientResponse);
        handleCloseModal();
      } catch (error) {
        // console.error("Error deleting contact:", error);
        swal.fire({
          title: "Error!",
          text: "Failed to delete contact.",
          type: "error",
          icon: "error",
          timer: 1500,
        });
      }
    } else {
      swal.fire({
        title: "Invalid Input!",
        text: "Please type 'delete' to confirm deletion.",
        type: "error",
        icon: "error",
        timer: 1500,
      });
    }
  };

  const handleDeleteContact = async () => {
    try {
      await handleContactDelete();
      setShowDeleteModal(false);
    } catch (error) {
      // console.error("Error deleting contact:", error);
      setShowDeleteModal(false);
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  // console.log("show clients details", clientDetails);

  return (
    <>
      <div className="col-lg-12 col-xl-12">
        {clientDetails.length > 0 ? (
          <>
            <Breadcrumb
              routeSegments={[
                {
                  name: "Dashboard",
                  path: "/dashboard/v1",
                },
                { name: "Client List", path: "/client/list" },
                { name: "Client Information" },
              ]}
            />
          </>
        ) : (
          <p>Loading...</p>
        )}
        {/* Modals */}
        <UpdateClientModal
          show={showClientModal}
          handleClose={handleCloseModal}
          formData={formData}
          handleFormChange={handleFormChange}
          handleSubmit={handleClientSubmit}
        />
        <UpdateContactModal
          show={showContactModal}
          handleClose={handleCloseModal}
          formData={contactFormData}
          handleFormChange={handleFormChangeForContact}
          handleSubmit={handleContactSubmit}
        />
        <DeleteContactModal
          show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={handleDeleteContact}
          handleDeleteConfirmationChange={handleDeleteConfirmationChange} // Pass the function as a prop
        />
      </div>

      <div className="row">
        {/* <div
          className="col-lg-2 col-xl-2 col-md-4 col-sm-4 col-12 "
          style={{ maxHeight: "500px", overflowY: "auto" }}
        >
          <style>
            {`
          .client-list-item {
            transition: background-color 0.3s ease;
          }

          .client-list-item:hover {
            background-color: #F1F3FA;
            cursor:pointer; 
          }
        `}
          </style>
          <ListGroup>
            <ListGroup.Item style={{ backgroundColor: "#DDE3EF" }}>
              <h5>Clients List</h5>
            </ListGroup.Item>

            {clientData.map((client) => (
              <ListGroup.Item
                key={client.uuid}
                className="client-list-item"
                onClick={() => handleClick(client.uuid)}
              >
                {client?.company_name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div> */}
        <div className="col-lg-12 col-md-12">
          <div className="d-flex justify-content-between align-items-center">
            <h4>{clientDetails[0]?.company_name}</h4>
          </div>

          <Tabs
            activeKey={activeTab}
            onSelect={(key) => setActiveTab(key)}
            className="mb-3"
          >
            <Tab eventKey="overview" title="Overview">
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                <div className="container-fluid mt-1 mb-1">
                  <div className="row">
                    <div className="col-lg-7 col-12">
                      {/* {" "} */}
                      <Card>
                        <Card.Body>
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ borderBottom: "1px solid #B0B0B0" }}
                          >
                            <h5>Basic Information</h5>

                            <Button
                              onClick={() => handleOpenClientModal()}
                              variant="secondary"
                              className="mb-2"
                            >
                              Edit
                            </Button>
                          </div>
                          <Row>
                            {clientDetails?.map((contact, contactIndex) => (
                              <div>
                                <h6>{clientDetails[0]?.company_name}</h6>
                                {/* <p>{clientDetails[0]?.email_address}</p>
                                <p>{clientDetails[0]?.phone_number}</p> */}
                              </div>
                            ))}
                          </Row>
                          <Accordion>
                            <Accordion.Item>
                              <Accordion.Header>
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  style={{ width: "100%" }}
                                >
                                  <div style={headerStyle}>
                                    <h5 style={headerTextStyle}>ADDRESS</h5>
                                  </div>
                                  {/* <Button
                                      variant="primary"
                                      onClick={() => handleOpenClientModal()}
                                      className="mx-2"
                                    >
                                      Edit
                                    </Button> */}
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div>
                                  <Row>
                                    <Col md={4}>
                                      <strong>Billing Address</strong>
                                    </Col>
                                    <div>
                                      <Col>
                                        {clientDetails[0]?.billing_address}{" "}
                                      </Col>
                                    </div>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col md={4}>
                                      <strong>Shipping Address</strong>
                                    </Col>
                                    <div>
                                      {clientDetails[0]?.shipping_address ? (
                                        <Col>
                                          {clientDetails[0]?.shipping_address}{" "}
                                        </Col>
                                      ) : (
                                        <div>---------------------</div>
                                      )}
                                    </div>
                                  </Row>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>

                          <Accordion>
                            <Accordion.Item>
                              <Accordion.Header>
                                <div style={headerStyle}>
                                  <h5 style={headerTextStyle}>OTHER DETAILS</h5>
                                </div>
                              </Accordion.Header>

                              <Accordion.Body>
                                <Row className="mt-2">
                                  <Col md={4}>
                                    <strong>Category</strong>
                                  </Col>
                                  <Col>{clientDetails[0]?.category}</Col>
                                </Row>
                                <Row className="mt-2">
                                  <Col md={4}>
                                    <strong>Gstin Number</strong>
                                  </Col>
                                  <Col>{clientDetails[0]?.gstin_number}</Col>
                                </Row>
                                <Row className="mt-2">
                                  <Col md={4}>
                                    <strong>Client Country</strong>
                                  </Col>

                                  <Col>{clientDetails[0]?.client_country} </Col>
                                </Row>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-lg-5 col-12">
                      <Card>
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div
                                className="d-flex justify-content-between align-items-center"
                                style={{ width: "100%" }}
                              >
                                <div style={headerStyle}>
                                  <h5 style={headerTextStyle}>
                                    CONTACT PERSONS
                                  </h5>
                                </div>
                                <Button
                                  variant="link"
                                  className="d-flex align-items-center me-1"
                                  onClick={handleAddContactModal}
                                  style={{ marginLeft: "auto" }}
                                >
                                  <BsPlusCircle size={15} />
                                </Button>
                              </div>
                            </Accordion.Header>

                            <Accordion.Body>
                              {clientDetails[0]?.client_contacts?.map(
                                (contact, contactIndex) => (
                                  <div
                                    key={contactIndex}
                                    className="row mb-3"
                                    style={{
                                      borderBottom: "1px solid #B0B0B0",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    <div className="col-md-10">
                                      <Row>
                                        <Col>
                                          <strong>Contact Name</strong>
                                        </Col>
                                        <Col>
                                          {contact.first_name}{" "}
                                          {contact.last_name}
                                        </Col>
                                      </Row>
                                      <Row className="mt-2">
                                        <Col>
                                          <strong>Email Address</strong>
                                        </Col>
                                        <Col>{contact.email_address}</Col>
                                      </Row>
                                      <Row className="mt-2">
                                        <Col>
                                          <strong>Phone Number</strong>
                                        </Col>
                                        <Col>{contact.phone_number}</Col>
                                      </Row>
                                      <Row className="mt-2">
                                        <Col>
                                          <strong
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            Alternate Phone Number
                                          </strong>
                                        </Col>
                                        <Col>
                                          {contact.alternate_phone_number}
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="col-md-1">
                                      <span className="d-flex align-items-center">
                                        <span
                                          style={{
                                            height: "18px",
                                            color: "green",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            handleOpenContactModal(contact);
                                          }}
                                          className="mx-2"
                                        >
                                          <i
                                            className="fa fa-pencil"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                        <span
                                          style={{
                                            height: "18px",
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            handleDeleteContactModal(contact);
                                          }}
                                        >
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                )
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="leads" title="Leads">
              <Card>
                <div
                  style={{
                    maxHeight: "500px",
                    overflowY: "auto",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <ToolkitProvider
                    keyField="id"
                    data={leadData}
                    columns={sortableColumn}
                    search
                  >
                    {(props) => (
                      <div>
                        <div className="d-flex justify-content-between align-items-center mb-4 ">
                          <SearchBar
                            {...props.searchProps}
                            style={{ width: "290px", height: "38px" }}
                          />

                          <div className="d-flex align-items-center">
                            <Button
                              variant="primary"
                              // style={{
                              //   backgroundColor: "purple",
                              //   borderColor: "purple",
                              //   color: "#fff",
                              // }}
                              onClick={handleAddLead}
                              className="mx-2 py-2"
                            >
                              <i className="i-Add-User mr-1 "></i> Add Lead
                            </Button>
                            <Button
                              style={{
                                backgroundColor: "#68C281",
                                borderColor: "#68C281",
                                height: "39px",
                                width: "98px",
                              }}
                              className="mx-2 py-2"
                              variant="primary"
                              onClick={handleExportDoc}
                            >
                              <i className="i-File-Download mr-1 mx-1"></i>
                              Export
                            </Button>
                            {renderFilterDropdown()}
                          </div>
                        </div>

                        <div className="table-responsive">
                          <BootstrapTable
                            bordered={false}
                            {...props.baseProps}
                            pagination={paginationFactory(paginationOptions)}
                            noDataIndication={() => <div>No Leads found</div>}
                            rowEvents={{
                              onClick: (e, row, rowIndex) => {
                                handleRowClick(row); // Call your row click handler
                              },
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </Card>
            </Tab>
            <Tab eventKey="leadQuotation" title="Lead Quotation">
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                {leadQuotation && leadQuotation.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={leadQuotation}
                    columns={leadColumn}
                    search
                  >
                    {(props) => (
                      <div>
                        <div className="d-flex justify-content-between align-items-center mb-4 ">
                          <SearchBar
                            {...props.searchProps}
                            style={{ width: "290px", height: "38px" }}
                          />
                        </div>

                        <div className="table-responsive">
                          <BootstrapTable
                            bordered={false}
                            {...props.baseProps}
                            pagination={paginationFactory(paginationOptions)}
                            noDataIndication={() => (
                              <div>No lead quotation data available</div>
                            )}
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                ) : (
                  <div>Select a Particular Lead to see the quotation </div>
                )}
              </div>
            </Tab>
          </Tabs>
          {/* Delete Confirmation Modal */}
          <Modal
            show={showLeadDeleteModal}
            onHide={() => setShowLeadDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Lead Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Are you sure you want to delete this lead? Type{" "}
                <code>delete</code> to confirm:
              </p>
              <Form.Control
                type="text"
                placeholder="Type 'delete' to confirm"
                value={deleteInput}
                onChange={(e) => setDeleteInput(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowLeadDeleteModal(false)}
              >
                Close
              </Button>
              <Button
                variant="danger"
                onClick={handleDeleteLead}
                disabled={deleteInput.toLowerCase() !== "delete"}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          {/* update handled by modal */}

          <Modal
            show={showUpdateModal}
            onHide={() => setShowUpdateModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Lead Handled by</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="employeeSelect">
                <Form.Label>Select Employee:</Form.Label>
                <Form.Select
                  as="select"
                  value={selectedEmployeeId}
                  onChange={(e) => setSelectedEmployeeId(e.target.value)}
                >
                  {employeeList.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {`${employee.first_name} ${employee.last_name}`}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowUpdateModal(false)}
              >
                Close
              </Button>
              <Button variant="primary" onClick={handleUpdateLead}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>

          {/* update lead status modal */}

          <Modal
            show={showUpdateStatusModal}
            onHide={() => setShowUpdateStatusModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Lead Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="statusSelect">
                <Form.Label>Select Status:</Form.Label>
                <Form.Select
                  as="select"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  <option value=""> Select status</option>
                  <option value="Quotation Pending">Quotation Pending</option>
                  <option value="Quotation Sent">Quotation Sent</option>
                  <option value="Waiting for Customer Response">
                    Waiting for Customer Response
                  </option>
                  <option value="Revised Quotation to be Sent">
                    Revised Quotation to be Sent
                  </option>
                  <option value="Order Closed with Power Tech">
                    Order Closed with Power Tech
                  </option>
                  <option value="Order Closed with Other Supplier">
                    Order Closed with Other Supplier
                  </option>
                  <option value="Project Delayed">Project Delayed</option>
                </Form.Select>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowUpdateStatusModal(false)}
              >
                Close
              </Button>
              <Button variant="primary" onClick={handleUpdateStatus}>
                Update Status
              </Button>
            </Modal.Footer>
          </Modal>

          <UpdateRemarksModal
            show={showUpdateRemarksModal}
            handleClose={() => setShowUpdateRemarksModal(false)}
            remarks={updatedRemarks}
            handleChange={(e) => setUpdatedRemarks(e.target.value)}
            handleUpdate={handleUpdateRemarks}
          />
        </div>
      </div>
    </>
  );
};

export default ClientInformation;
