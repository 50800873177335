import AuthGuard from "./auth/AuthGuard";
import { createBrowserRouter, redirect } from "react-router-dom";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import uiKitsRoutes from "./views/ui-kits/uiKitsRoutes";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import inboxRoutes from "./views/app/inbox/inboxRoutes";
import Error404 from "./views/sessions/Error";
import clientRoutes from "./views/client/clientRoutes";
import leadroutes from "./views/Leads/leadroutes";
import quotatationroutes from "./views/Quotation/quotationroutes";
import leavesroutes from "./views/AdminSettings/leaves/leavesroutes";
import employeeroutes from "./views/AdminSettings/employeeroutes";
import orderroutes from "./views/order/orderroutes";
import purchaseroutes from "./views/purchase/purchaseroutes";
import storeroutes from "./views/Store/storeroutes";
import Unauthorized from "./views/sessions/Unauthorized";
import attendenceroutes from "./views/AdminSettings/Attendence/attendenceroutes";
import holidayroutes from "./views/AdminSettings/MonthlyHoliday/holidayroutes";
import loanroutes from "./views/AdminSettings/Loans/loanroutes";
const routes = createBrowserRouter([
  {
    element: <AuthGuard />,
    children: [
      ...dashboardRoutes,
      ...uiKitsRoutes,
      ...inboxRoutes,
      ...purchaseroutes,
      ...clientRoutes,
      ...leadroutes,
      ...quotatationroutes,
      ...leavesroutes,
      ...employeeroutes,
      ...orderroutes,
      ...storeroutes,
      ...attendenceroutes,
      ...holidayroutes,
      ...loanroutes
    ],
  },
  ...sessionsRoutes,
  {
    path: "/",
    exact: true,
    loader: () => redirect("/sessions/signin"),
  },
  {
    path: "/sessions/unauthorized",
    element: <Unauthorized />,
  },
  {
    path: "/sessions/404",
    element: <Error404 />,
  },
]);
export default routes;
