import axios from "axios";
import { serverAddress } from "constants";

const axiosInstance = axios.create({
  baseURL: serverAddress,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data) {
      // console.error("Request failed:", error.response.data);

      throw new Error(error.response.data.message || "Request failed");
    }
    // console.error("Request failed:", error.message);
    throw error;
  }
);

const config = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

export const fetchEmployees = async (token) => {
  try {
    const response = await axiosInstance.get(
      "/employee/getEmployees",
      config(token)
    );
    return response.data.employees;
  } catch (error) {
    throw error;
  }
};

export const deleteEmployee = async (id, token, transferEmployeeId, oldEmployeeName) => {

  console.log("employee id:",id);
  console.log("transfered employee id",transferEmployeeId);
  console.log("old employee name:",oldEmployeeName);

  try {
    console.log("line 89:", token);
    const response = await axiosInstance.put(
      `/employee/employee/${id}`,
      {
        employee_id: transferEmployeeId,
        old_employee_name: oldEmployeeName,
      },
      config(token)
    );
    console.log(`Employee with ID ${id} deleted successfully`);
    return response.data;
  } catch (error) {
    console.error("Error deleting employee:", error.message);
    throw error;
  }
};


export const updateEmployee = async (updatedData, token) => {
  try {
    const response = await axiosInstance.put(
      `/employee/employee`,
      updatedData,
      config(token)
    );
    return response.data;
  } catch (error) {
    // throw new Error('Error updating employee:',error.message);
    throw error;
  }
};

export const createEmployee = async (employeeData, token) => {
  try {
    const response = await axiosInstance.post(
      "/employee/create",
      employeeData,
      config(token)
    );
    return response.data;
  } catch (error) {
    // throw new Error('Error creating employee:',error.message);
    throw error;
  }
};

export const changeEmployeePassword = async (employeeData, token) => {
  try {
    const response = await axiosInstance.put(
      "/employee/password",
      employeeData,
      config(token)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const changeEmployeeStatus = async (employeeData, token) => {
  try {
    const response = await axiosInstance.put(
      "/employee/status",
      employeeData,
      config(token)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadOfferLetter = async (employee_id, offer_date, token) => {
  try {
    const requestBody = {
      employee_id: Number(employee_id),
      offer_date: offer_date,
    };
    const response = await axiosInstance.post(
      "/employee/offer/letter",
      requestBody,
      config(token)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const downloadJoiningLetter = async (
  employee_id,
  joining_date,
  token
) => {
  try {
    const requestBody = {
      employee_id: Number(employee_id),
      joining_date: joining_date,
    };
    const response = await axiosInstance.post(
      "/employee/joining/letter",
      requestBody,
      config(token)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRoles = async (token) => {
  try {
    const response = await axiosInstance.get("/role/all", config(token));
    return response.data.roles;
  } catch (error) {
    throw error;
  }
};

export const downloadTerminationLetter = async (
  employee_id,
  last_working_date,
  termination_reason,
  token
) => {
  try {
    const requestBody = {
      employee_id: Number(employee_id),
      last_working_date: last_working_date,
      termination_reason: termination_reason,
    };
    const response = await axiosInstance.post(
      "/employee/termination/letter",
      requestBody,
      config(token)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchEmployeeById = async (employeeId, token) => {
  try {
    const response = await axiosInstance.get(
      `/employee/${employeeId}`,
      config(token)
    );
    return response.data.employee_details; // or response.data if you want all data
  } catch (error) {
    throw error;
  }
};

export const incrementEmployeeSalary = async (incrementData, token) => {
  try {
    const response = await axiosInstance.post(
      "/employee/increment",
      incrementData,
      config(token)
    );
    return response.data; // Returns the entire response for flexibility
  } catch (error) {
    throw error; // Propagates the error to the caller
  }
};

export const fetchAllSalaryMasters = async (token) => {
  try {
    const response = await axiosInstance.get("/salarymaster/getAll", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200 && !response.data.error) {
      return response.data.salary_masters; // Return salary masters data
    } else {
      throw new Error(response.data.message || "Error fetching salary masters");
    }
  } catch (error) {
    console.error("Error in fetchAllSalaryMasters:", error);
    throw error;
  }
};

export const fetchDocumentTypes = async (token) => {
  try {
    const response = await axiosInstance.get("/document/type/getAll", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200 && !response.data.error) {
      return response.data.document_types; // Return document types
    } else {
      throw new Error(response.data.message || "Error fetching document types");
    }
  } catch (error) {
    console.error("Error in fetchDocumentTypes:", error);
    throw error;
  }
};

export const uploadDocument = async (
  file,
  employee_id,
  document_type_id,
  token
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("employee_id", employee_id);
    formData.append("document_type_id", document_type_id);

    const response = await axiosInstance.post(
      "/employee/upload/document",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 200 && !response.data.error) {
      return response.data; // Return response data for further use
    } else {
      throw new Error(response.data.message || "Error uploading document");
    }
  } catch (error) {
    console.error("Error in uploadDocument:", error);
    throw error;
  }
};

export const updateDocument = async (
  file,
  employee_id,
  document_type_id,
  document_id,
  token
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("employee_id", employee_id);
    formData.append("document_type_id", document_type_id);
    formData.append("document_id", document_id);

    const response = await axiosInstance.put(
      "/employee/update/document",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 200 && !response.data.error) {
      return response.data; // Return response data for further use
    } else {
      throw new Error(response.data.message || "Error updating document");
    }
  } catch (error) {
    throw error;
  }
};

export const viewDocument = async (document_id, token) => {
  try {
    const response = await axiosInstance.get(
      `/employee/download/document/${document_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200 && !response.data.error) {
      return response.data; // Return document URL or data for further use
    } else {
      throw new Error(response.data.message || "Error fetching document");
    }
  } catch (error) {
    throw error;
  }
};

export const deleteDocument = async (employee_id, document_type_id, token) => {
  try {
    const response = await axiosInstance.post(
      "/employee/delete/document",
      {
        employee_id,
        document_type_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200 && !response.data.error) {
      return response.data; // Return response data for further use
    } else {
      throw new Error(response.data.message || "Error deleting document");
    }
  } catch (error) {
    throw error;
  }
};

export const calculateEmployeeSalary = async (employee_id, month, year, token) => {
  try {
    const requestBody = {
      employee_id: Number(employee_id),
      month: Number(month),
      year: Number(year),
    };

    const response = await axiosInstance.post(
      "/salary/calculate",
      requestBody,
      config(token)
    );

    if (response.status === 200 && !response.data.error) {
      return response.data; // Returns the salary calculation details
    } else {
      throw new Error(response.data.msg || "Error calculating salary");
    }
  } catch (error) {
    console.error("Error in calculateEmployeeSalary:", error);
    throw error;
  }
};

export const fetchSalaries = async (employee_id, month, year, page, limit, token) => {
  try {
    const response = await axiosInstance.get(
      `/salary/get`,
      {
        params: {
          employee_id: employee_id ? Number(employee_id) : undefined,
          month: month ? Number(month) : undefined,
          year: year ? Number(year) : undefined,
          page: page || 1,
          limit: limit || 10,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200 && !response.data.error) {
      return response.data; // Returns salary details
    } else {
      throw new Error(response.data.message || "Error fetching salaries");
    }
  } catch (error) {
    console.error("Error in fetchSalaries:", error);
    throw error;
  }
};




export default axiosInstance;
